<template>
  <div>
    <div class="push-configuration">
      <h1 v-if="!configureWebhook && !configureFireBase">Live Match Push Delivery</h1>
      <h1 v-else-if="configureWebhook">Webhook</h1>
      <h1 v-else>FireBase</h1>
      <div class="container">
        <div class="align-left">
          <h2 class="configure-title" v-if="configureWebhook || configureFireBase">
            <svg>
              <use v-bind="{'xlink:href':'#settings-icon'}" />
            </svg>
            Configure
          </h2>
          <button class="back-btn" v-if="configureWebhook || configureFireBase" @click="back()">
            Back</button>
          <div class="webhook-wrapper" v-if="webhook">
            <div class="inner-wrapper"
              v-if="!configureWebhook"
              :class="[pushwebhookDetails.params.hook_1 || pushwebhookDetails.params.hook_2 ?
              'remove-radius': '']"
            >
              <img src="@/assets/webhook-icon.png" />
              <h2>Webhook</h2>
              <button class="btn-style-common medium outlined"
               @click="configure('webhook')"
              >Configure</button>
            </div>
            <div v-if="(pushwebhookDetails.params.hook_1 || pushwebhookDetails.params.hook_2) && showWebhookBlock"
              class="webhook-details"
              :class="[configureWebhook ? 'add-radius': '']"
            >
              <h3>Endpoints</h3>
              <div v-if="pushwebhookDetails.params.hook_1">
                <span>1</span>
                <p>{{pushwebhookDetails.params.hook_1}}</p>
                <svg class="edit" @click="editWebhook('1')"
                  v-if="configureWebhook">
                  <use v-bind="{'xlink:href':'#edit'}" />
                </svg>
              </div>
              <div v-if="pushwebhookDetails.params.hook_2">
                <span>2</span>
                <p>{{pushwebhookDetails.params.hook_2}}</p>
                <svg class="edit" @click="editWebhook('2')"
                 v-if="configureWebhook">
                  <use v-bind="{'xlink:href':'#edit'}" />
                </svg>
              </div>
            </div>
            <div class="webhook-content" v-if="editWebhookContent">
              <template v-if="addEndpoint || editFirstUrl">
                <h3>Endpoint 1</h3>
                <div class="webhook__endpoint-wrapper" v-if="addEndpoint || editFirstUrl">
                  <input class="endpoint1" type="text"
                    v-model="pushwebhookDetails.params.hook_1" placeholder="www.cricketapi.com">
                </div>
              </template>
              <template v-if="addEndpoint || editSecondUrl">
                <h3>Endpoint 2</h3>
                <div class="webhook__endpoint-wrapper" v-if="addEndpoint || editSecondUrl">
                  <input
                    class="endpoint2"
                    type="text" v-model="pushwebhookDetails.params.hook_2"
                    placeholder="https://api.sports.roanuz.com/v5/cricket">
                </div>
              </template>
              <button v-if="!addEndpoint && (!editFirstUrl || !editSecondUrl)"
                @click="addEndpoint = true"
                class="add-endpoint">Add another endpoint</button>
              <div class="queries-wrapper">
                <button
                  class="btn-style-common medium outlined"
                  @click="cancelWebhookConfigure()">
                  Cancel</button>
                <button class="btn-style-common medium"
                  @click="postData('webhook')">Confirm</button>
              </div>
              <span class="alert-msg">Changes may take upto 45mins to reflect!</span>
            </div>
          </div>
          <div class="firebase-wrapper" v-if="firebase">
            <div class="inner-wrapper"
              v-if="!configureFireBase"
              :class="[pushfirebaseDetails.params.pushAuthCode || pushfirebaseDetails.params.dbUrl
              || pushfirebaseDetails.params.serviceData ?
              'remove-radius': '']"
            >
              <img src="@/assets/firebase-icon.png" />
              <h2>FireBase</h2>
              <button class="btn-style-common medium outlined"
                @click="configure('firebase')"
              >Configure</button>
            </div>
            <div v-if="(pushfirebaseDetails.params.pushAuthCode || pushfirebaseDetails.params.dbUrl
              || pushfirebaseDetails.params.serviceData) && showFirebaseBlock"
              class="firebase-details"
              :class="[configureFireBase ? 'add-radius': '']"
            >
              <div v-if="pushfirebaseDetails.params.pushAuthCode">
                <span>1</span>
                <p><span>PushAuthCode</span> - {{pushfirebaseDetails.params.pushAuthCode}}</p>
                <svg class="edit" @click="editFirebase('1')"
                  v-if="configureFireBase">
                  <use v-bind="{'xlink:href':'#edit'}" />
                </svg>
              </div>
              <div v-if="pushfirebaseDetails.params.dbUrl">
                <span>2</span>
                <p><span>dbUrl</span> - {{pushfirebaseDetails.params.dbUrl}}</p>
                <svg class="edit" @click="editFirebase('2')"
                  v-if="configureFireBase">
                  <use v-bind="{'xlink:href':'#edit'}" />
                </svg>
              </div>
              <div v-if="pushfirebaseDetails.params.serviceData">
                <span>3</span>
                <p><span>ServiceData</span> - {{pushfirebaseDetails.params.serviceData}}</p>
                <svg class="edit" @click="editFirebase('3')"
                  v-if="configureFireBase">
                  <use v-bind="{'xlink:href':'#edit'}" />
                </svg>
              </div>
            </div>
            <div class="firebase-content" v-if="editFireBaseContent">
              <div class="firebase-content-wrapper" v-if="editPushAuthcode">
                <h3>Push Auth Code</h3>
                <button class="generate-btn" @click="generateAuthCode(Math.floor(100000 + Math.random() * 900000))">
                  Generate
                </button>
                <div class="input-box">
                  <input class="auth-code"
                    type="text" placeholder="Enter the generated Push Auth Code"
                    v-model="pushfirebaseDetails.params.pushAuthCode">
                </div>
              </div>
              <div class="firebase-content-wrapper" v-if="editDbUrl">
                <h3>DB URL</h3>
                <div class="input-box">
                  <input class='db-url'
                    type="text" placeholder="Enter your Firebase Database URL."
                    v-model="pushfirebaseDetails.params.dbUrl">
                </div>
              </div>
              <div class="firebase-content-wrapper" v-if="editServiceData">
                <h3>Service Data</h3>
                <div class="input-box">
                  <textarea rows="5" v-if="!jsonObj || showParsedJson"
                    placeholder="Paste your Firebase Service Account Config JSON"
                    id="service_data"
                    v-model="serviceData"
                    autocomplete='serviceData'
                    class="json-box"></textarea>
                  <json-preview v-if="jsonObj && !showParsedJson" :src="JSON.parse(jsonObj)" />
                </div>
                <p v-if="errorMsg" class="error-msg">Please provide vaild JSON Data</p>
              </div>
              <div class="queries-wrapper">
                <button
                  class="btn-style-common medium outlined"
                  @click="cancelFireBaseConfigure()">
                  Cancel</button>
                <button class="btn-style-common medium"
                  @click="postData('firebase')">Confirm</button>
              </div>
            </div>
          </div>
        </div>
        <div class="align-right">
          <div class="webhook-promo" v-if="configureWebhook">
            <h2><img src="@/assets/webhook-icon.png"/>Match - via Webhook</h2>
            <div class="inner-container">
              <div class="flex-item-wrapper">
                <div class="flex-item-header" @click="collapseSection(0)">
                  <div class="flex-box clickable">
                    <h4 class="title">Send a Subscription Request</h4>
                    <span class="expand" :class="[opened.includes(0) ? '': 'hide']"></span>
                  </div>
                </div>
                <div class="rs-flex-box" :class="[opened.includes(0) ? 'show': 'hide']">
                  <div class="inner-box">
                    <p>Once your console is set up, you will have to subscribe to
                      the matches individually for receiving automatic updates.
                      The sample snippet below demonstrates how to send a subscription request
                      from the Match Subscribe API.</p>
                    <a :href="link+ 'pages/match-webhook'"
                      class="btn-style-common medium outlined">
                     Learn more</a>
                  </div>
                </div>
              </div>
              <div class="flex-item-wrapper">
                <div class="flex-item-header" @click="collapseSection(1)">
                  <div class="flex-box clickable">
                    <h4 class="title">Handle the data received</h4>
                    <span class="expand" :class="[opened.includes(1) ? '': 'hide']"></span>
                  </div>
                </div>
                <div class="rs-flex-box" :class="[opened.includes(1) ? 'show': 'hide']">
                  <div class="inner-box">
                    <p>Once successfully set up, Roanuz Cricket API servers will automatically
                      start pushing data to your systems as the match progresses.
                      The sample snippet below demonstrates how to handle the data once
                      it is pushed by the server.</p>
                    <a :href="link+ 'pages/match-webhook'"
                      class="btn-style-common medium outlined">
                      Learn more</a>
                  </div>
                </div>
              </div>
              <div class="flex-item-wrapper">
                <div class="flex-item-header" @click="collapseSection(2)">
                  <div class="flex-box clickable">
                    <h4 class="title">Unsubscribe from a Match</h4>
                    <span class="expand" :class="[opened.includes(2) ? '': 'hide']"
                    ></span>
                  </div>
                </div>
                <div class="rs-flex-box" :class="[opened.includes(2) ? 'show': 'hide']">
                  <div class="inner-box">
                    <p>For any reasons, if you wish to opt-out of automatic updates for a match,
                      you can do so by placing an unsubscribe request from the Match Unsubscribe API.</p>
                    <a :href="link+ 'pages/match-webhook'"
                      class="btn-style-common medium outlined">
                      Learn more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="firebase-promo" v-else-if="configureFireBase">
            <h2><img src="@/assets/firebase-icon.png"/>Match - via Firebase</h2>
            <div class="inner-container">
              <div class="flex-item-wrapper">
                <div class="flex-item-header" @click="collapseSection(0)">
                  <div class="flex-box clickable">
                    <h4 class="title">Configuring your Console</h4>
                    <span class="expand" :class="[opened.includes(0) ? '': 'hide']"></span>
                  </div>
                </div>
                <div class="rs-flex-box" :class="[opened.includes(0) ? 'show': 'hide']">
                  <div class="inner-box">
                    <p>To receive automatic match updates from Roanuz Cricket API,
                      you are required to share a service account with us.
                      From your Firebase Console, go to Project Settings and open the Service
                      Accounts tab. In the Service accounts settings, click on the "Manage service
                      account permissions" option.</p>
                    <a :href="link+ 'pages/match-firebase'"
                      class="btn-style-common medium outlined">
                      Read more</a>
                  </div>
                </div>
              </div>
              <div class="flex-item-wrapper">
                <div class="flex-item-header" @click="collapseSection(1)">
                  <div class="flex-box clickable">
                    <h4 class="title">Sending a Subscription Request</h4>
                    <span class="expand" :class="[opened.includes(1) ? '': 'hide']"></span>
                  </div>
                </div>
                <div class="rs-flex-box" :class="[opened.includes(1) ? 'show': 'hide']">
                  <div class="inner-box">
                    <p>You have to subscribe to the matches individually for receiving automatic updates.
                      The Sample snippet below demonstrates how to send a subscription request.
                      Please note that subscribing twice to the same match does not mean that you will
                      be charged twice, or you will receive the same update twice.</p>
                    <a :href="link+ 'pages/match-firebase'"
                      class="btn-style-common medium outlined">
                      Learn more</a>
                  </div>
                </div>
              </div>
              <div class="flex-item-wrapper">
                <div class="flex-item-header" @click="collapseSection(2)">
                  <div class="flex-box clickable">
                    <h4 class="title">Handling the data received</h4>
                    <span class="expand" :class="[opened.includes(2) ? '': 'hide']"></span>
                  </div>
                </div>
                <div class="rs-flex-box" :class="[opened.includes(2) ? 'show': 'hide']">
                  <div class="inner-box">
                    <p>Once successfully set up, Roanuz Cricket API servers will
                      automatically start pushing data to your Firebase Realtime Database as the match progresses.
                      You can easily include the data in your application by using the Firebase APIs.</p>
                    <a :href="link+ 'pages/match-firebase'"
                      class="btn-style-common medium outlined">
                      Learn more</a>
                  </div>
                </div>
              </div>
               <div class="flex-item-wrapper">
                <div class="flex-item-header" @click="collapseSection(3)">
                  <div class="flex-box clickable">
                    <h4 class="title">Unsubscribing from a Match</h4>
                    <span class="expand" :class="[opened.includes(3) ? '': 'hide']"></span>
                  </div>
                </div>
                <div class="rs-flex-box" :class="[opened.includes(3) ? 'show': 'hide']">
                  <div class="inner-box">
                    <p>For any reasons, if you wish to opt-out of receiving automatic updates for a
                      match, you can do so by placing an unsubscribe request from the Match Unsubscribe API.</p>
                    <a :href="link+ 'pages/match-firebase'"
                      class="btn-style-common medium outlined">
                      Learn more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="match-via-push-promo" v-else>
            <img src="@/assets/promo-push-config.png" />
            <p><span>Match via Push</span> is our initiative to ensure the fastest data delivery.
              Whenever an event happens in a live cricket match, we update the data and
              deliver them automatically to your systems as push notifications.
              You can choose from Webhook, WebSocket and Firebase as your means to receive the updates</p>
            <a :href="link+ 'docs/guides/match-push'"
              class="btn-style-common medium outlined">
              Learn more about Match via Push</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Config from '@/config';
import JsonPreview from '@/components/JsonPreview.vue';

export class PushConfiguration {
  constructor() {
    this.key = null;
    this.active_delivery_method = null;
    this.delivery_method = null;
    this.params = {};
    this.action = null;
  }

  static typeName() {
    return 'PushConfiguration';
  }
}

export default {
  name: 'pushConfiguration',
  components: {
    JsonPreview,
  },
  data() {
    return {
      showWebhookContent: false,
      showFirebaseContent: false,
      jsonObj: null,
      showParsedJson: false,
      isConfirmed: false,
      showChangeMethod: false,
      errorMsg: false,
      pushwebhookDetails: new PushConfiguration(),
      pushfirebaseDetails: new PushConfiguration(),
      serviceData: null,
      pushNotifications: null,
      link: Config.sportsHost,
      configureWebhook: false,
      configureFireBase: false,
      editFireBaseContent: false,
      editWebhookContent: false,
      addEndpoint: false,
      editFirstUrl: false,
      editSecondUrl: false,
      editPushAuthcode: false,
      editDbUrl: false,
      editServiceData: false,
      firebase: true,
      webhook: true,
      opened: [0],
      showWebhookBlock: false,
      showFirebaseBlock: false,
    };
  },
  watch: {
    serviceData() {
      if (this.serviceData) {
        try {
          this.pushfirebaseDetails.params.serviceData = this.serviceData;
          JSON.parse(this.serviceData);
          this.jsonObj = this.serviceData;
          this.showParsedJson = false;
        } catch (e) {
          this.errorMsg = true;
        }
      }
    },
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
  },
  mounted() {
    this.webhookInfo();
    this.fireBaseInfo();
    this.getPushNotifications();
  },
  methods: {
    cancelWebhookConfigure() {
      this.configureWebhook = false;
      this.editWebhookContent = false;
      this.editFirstUrl = false;
      this.editSecondUrl = false;
      this.addEndpoint = false;
      this.webhook = true;
      this.firebase = true;
      this.$route.query = '';
      this.assignWebhookInfo();
    },
    cancelFireBaseConfigure() {
      this.configureFireBase = false;
      this.editFireBaseContent = false;
      this.editPushAuthcode = false;
      this.editDbUrl = false;
      this.editServiceData = false;
      this.webhook = true;
      this.firebase = true;
      this.$route.query = '';
      this.assignFirebaseInfo();
    },
    assignWebhookInfo() {
      this.pushwebhookDetails.params.hook_1 = (this.pushNotifications && this.pushNotifications.web_hook[0]
        && this.pushNotifications.web_hook[0] && this.pushNotifications.web_hook[0].params
        && this.pushNotifications.web_hook[0].params.hook_1) || '';
      this.pushwebhookDetails.params.hook_2 = (this.pushNotifications && this.pushNotifications.web_hook[0]
        && this.pushNotifications.web_hook[0] && this.pushNotifications.web_hook[0].params
        && this.pushNotifications.web_hook[0].params.hook_2) || '';
      if (this.pushwebhookDetails.params.hook_1 !== '' || this.pushwebhookDetails.params.hook_2 !== '') {
        this.showWebhookBlock = true;
      } else {
        this.showWebhookBlock = false;
      }
    },
    assignFirebaseInfo() {
      this.pushfirebaseDetails.params.dbUrl = (this.pushNotifications && this.pushNotifications.google_firebase[0]
        && this.pushNotifications.google_firebase[0].params && this.pushNotifications.google_firebase[0].params.dbUrl)
        || '';
      this.pushfirebaseDetails.params.pushAuthCode = (this.pushNotifications
        && this.pushNotifications.google_firebase[0]
        && this.pushNotifications.google_firebase[0].params
        && this.pushNotifications.google_firebase[0].params.pushAuthCode)
        || '';
      this.pushfirebaseDetails.params.serviceData = (this.pushNotifications && this.pushNotifications.google_firebase[0]
        && this.pushNotifications.google_firebase[0].params
        && this.pushNotifications.google_firebase[0].params.serviceData) || '';
      if (this.pushfirebaseDetails.params.dbUrl !== ''
        || this.pushfirebaseDetails.params.pushAuthCode !== ''
        || this.pushfirebaseDetails.params.serviceData !== '') {
        this.showFirebaseBlock = true;
      } else {
        this.showFirebaseBlock = false;
      }
    },
    back() {
      this.configureWebhook = false;
      this.configureFireBase = false;
      this.editFireBaseContent = false;
      this.editWebhookContent = false;
      this.webhook = true;
      this.$route.query = '';
      this.firebase = true;
      this.assignFirebaseInfo();
      this.assignWebhookInfo();
    },
    configure(method) {
      if (method === 'webhook') {
        this.firebase = false;
        this.opened = [0];
        this.$route.query = 'next-step';
        if (this.pushwebhookDetails.params.hook_1 || this.pushwebhookDetails.params.hook_2) {
          this.configureWebhook = true;
        } else {
          this.configureWebhook = true;
          this.editFirstUrl = true;
          this.editSecondUrl = true;
          this.editWebhookContent = true;
        }
      } else if (method === 'firebase') {
        this.webhook = false;
        this.opened = [0];
        this.$route.query = 'next-step';
        if (this.pushfirebaseDetails.params.pushAuthCode && this.pushfirebaseDetails.params.dbUrl
            && this.pushfirebaseDetails.params.serviceData) {
          this.configureFireBase = true;
        } else {
          this.configureFireBase = true;
          this.editServiceData = true;
          this.editDbUrl = true;
          this.editPushAuthcode = true;
          this.editFireBaseContent = true;
        }
      }
    },
    generateAuthCode(code) {
      this.pushfirebaseDetails.params.pushAuthCode = code;
    },
    editWebhook(index) {
      this.editWebhookContent = true;
      this.showWebhookBlock = false;
      if (index === '1') {
        this.editFirstUrl = true;
      } else {
        this.editSecondUrl = true;
      }
    },
    editFirebase(index) {
      this.editFireBaseContent = true;
      this.showFirebaseBlock = false;
      if (index === '1') {
        this.editPushAuthcode = true;
      } else if (index === '2') {
        this.editDbUrl = true;
      } else {
        this.editServiceData = true;
        this.showParsedJson = true;
      }
    },
    updateWebhookData() {
      this.pushwebhookDetails.active_delivery_method = 'web_hook';
      this.pushwebhookDetails.key = this.pushNotifications.web_hook[0].key;
      this.assignWebhookInfo();
      this.pushwebhookDetails.action = 'update_delivery_method_config';
      this.firebase = true;
      this.showWebhookBlock = true;
    },
    updateFirebaseData() {
      this.pushfirebaseDetails.delivery_method = 'google:firebase';
      this.pushfirebaseDetails.key = this.pushNotifications.google_firebase[0].key;
      this.assignFirebaseInfo();
      this.serviceData = this.pushNotifications.google_firebase[0].params.serviceData;
      this.pushfirebaseDetails.action = 'update_delivery_method_config';
      this.webhook = true;
      this.showFirebaseBlock = true;
    },

    webhookInfo() {
      this.pushwebhookDetails.delivery_method = 'web_hook';
      this.pushwebhookDetails.active_delivery_method = 'web_hook';
      if (this.pushNotifications && this.pushNotifications.web_hook
        && this.pushNotifications.web_hook[0]) {
        this.updateWebhookData();
      } else {
        this.pushwebhookDetails.params.hook_1 = '';
        this.pushwebhookDetails.params.hook_2 = '';
        this.pushwebhookDetails.action = 'create_delivery_method_config';
      }
      return this.pushwebhookDetails;
    },

    fireBaseInfo() {
      this.pushfirebaseDetails.delivery_method = 'google:firebase';
      this.pushfirebaseDetails.active_delivery_method = 'google:firebase';
      if (this.pushNotifications && this.pushNotifications.google_firebase
        && this.pushNotifications.google_firebase[0]) {
        this.updateFirebaseData();
      } else {
        this.pushfirebaseDetails.params.dbUrl = '';
        this.pushfirebaseDetails.params.pushAuthCode = '';
        this.pushfirebaseDetails.params.serviceData = '';
        this.serviceData = this.pushfirebaseDetails.params.serviceData;
        this.pushfirebaseDetails.action = 'create_delivery_method_config';
      }
      return this.pushfirebaseDetails;
    },
    postData(method) {
      if (method === 'webhook') {
        this.activeProject.postProjectActions(this.pushwebhookDetails).then(() => {
          this.showChangeMethod = true;
          this.getPushNotifications();
          this.$route.query = '';
          this.editWebhookContent = false;
          this.configureWebhook = false;
        });
      } else {
        this.activeProject.postProjectActions(this.pushfirebaseDetails).then(() => {
          this.isConfirmed = true;
          this.getPushNotifications();
          this.$route.query = '';
          this.editFireBaseContent = false;
          this.configureFireBase = false;
        });
      }
    },
    getPushNotifications() {
      if (this.activeProject.key) {
        this.activeProject.getPushConfiguration(this.activeProject.key).then((resp) => {
          this.pushNotifications = resp;
          if (resp) {
            if (resp.active_delivery_method === 'web_hook') {
              this.showWebhookContent = true;
              this.showFirebaseContent = false;
            } else if (resp.active_delivery_method === 'google:firebase') {
              this.showFirebaseContent = true;
              this.showWebhookContent = false;
            }
            if (resp.web_hook.length) {
              this.updateWebhookData();
            }
            if (resp.google_firebase.length) {
              this.updateFirebaseData();
            }
          }
        });
      }
    },
    collapseSection(id) {
      if (this.configureWebhook || this.configureFireBase) {
        const index = this.opened.indexOf(id);
        const contentContainer = document.getElementsByClassName('rs-flex-box')[id];
        if (contentContainer) {
          if (index > -1) {
            this.opened.splice(index, 1);
            // contentContainer.classList.add('hide');
            contentContainer.setAttribute('style', '');
          } else {
            this.opened.push(id);
            // contentContainer.classList.remove('hide');
            contentContainer.style.height = `${contentContainer.scrollHeight}px`;
            contentContainer.style.minHeight = `${contentContainer.scrollHeight}px`;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.push-configuration {
  margin-bottom: size(50);
  h1 {
    @include font-styles($type: 'display', $size: 'xs', $weight: 'bold');
    margin-bottom: size(30);
  }
  .container {
    display: flex;
    justify-content: space-between;
    margin-bottom: size(50);
    .align-left {
      width: size(610);
      &:after {
        content: "";
        clear: both;
        display: block;
      }
    }
    .configure-title {
      @include font-styles($type: 'text', $size: 'lg', $weight: 'bold');
      padding-bottom: size(14);
      display: inline-block;
      svg {
        display: inline-block;
        vertical-align: middle;
        width: size(23);
        height: size(21);
        margin-right: size(10);
      }
    }
    .back-btn {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
      float: right;
      cursor: pointer;
      color: var(--global--common-link--Color);
    }
    .align-right {
      width: size(380);
      .match-via-push-promo {
        padding: size(20);
        background: var(--global--white--Color);
        box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
        0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
        border-radius: size(8);
        img {
          display: inline-block;
          width: 100%;
          margin: 0 auto size(20);
        }
        p {
          @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
          padding-bottom: size(20);
          span {
            @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
          }
        }
      }
      .webhook-promo, .firebase-promo {
        padding: size(20) size(20) 0;
        background: var(--global--white--Color);
        box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
        0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
        border-radius: size(8);
        position: relative;
        h2 {
          @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
          img {
            display: inline-block;
            vertical-align: middle;
            width: size(44);
            height: size(44);
            margin-right: size(10);
          }
          padding-bottom: size(20);
          border-bottom: size(1) solid var(--global--border--Color);
        }
        .expand {
          &.hide {
            &:after {
              transform:rotate(90deg);
            }
            &:before {
              transform: initial;
            }
          }
        }
        .flex-item-wrapper {
          padding: size(20) 0;
          border-bottom: size(1) solid var(--global--border--Color);
          &:last-child {
            border-bottom: none;
          }
          .rs-flex-box {
            transition: height .6s cubic-bezier(.645,.045,.355,1), opacity .3s cubic-bezier(.645,.045,.355,1),
            min-height .6s cubic-bezier(.645,.045,.355,1);
            opacity: 1;
            height: 0;
            min-height: 0;
            overflow: hidden;
            &.hide {
              opacity: 0;
            }
          }
          h4 {
            @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
            display: inline-block;
            width: 94%;
            padding-bottom: size(6);
            cursor: pointer;
          }
          p {
            @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
            padding-bottom: size(20);
          }
        }
        .expand {
          cursor: pointer;
          position:relative;
          padding: 0 size(10);
          width: size(16);
          height: size(16);
          background-color: transparent;
          border: 0;
          display: inline-block;
          &:before, &:after {
            content: "";
            position: absolute;
            background-color: var(--global--main--title--Color);
            transition: transform 300ms cubic-bezier(.694,0,.335,1);
            width: size(2);
            height: size(16);
            top: 0;
          }
          &:after {
            transform: rotate(270deg);
          }
          &:before {
            transform: rotate(90deg);
          }
        }
      }
      .webhook-promo {
        .flex-item-wrapper {
          &:first-child {
            .rs-flex-box {
              &.show {
                height: size(182);
                min-height: size(182);
              }
            }
          }
        }
      }
      .firebase-promo {
        .flex-item-wrapper {
          &:first-child {
            .rs-flex-box {
              &.show {
                height: size(203);
                min-height: size(203);
              }
            }
          }
        }
      }
    }
    .inner-wrapper {
      padding: size(15);
      border-radius: size(8);
      background: #F7C64B;
      &.remove-radius {
        border-radius: size(8) size(8) 0 0;
      }
      img {
        display: inline-block;
        vertical-align: top;
        width: size(44);
        height: size(44);
        margin-right: size(10);
      }
      h2 {
        display: inline-block;
        vertical-align: top;
        margin-top: size(10);
        color: var(--global--main-title--Color);
        @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
      }
      button{
        float: right;
        cursor: pointer;
        margin-top: size(3);
      }
    }
    .webhook-details, .firebase-details {
      padding: size(20);
      background: var(--global--white--Color);
      box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
       0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
      border-radius: 0 0 size(8) size(8);
      margin-bottom: size(30);
      &.add-radius {
        border-radius: size(8);
      }
      h3 {
        @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
        color: var(--global--main--title--Color);
      }
      p {
        @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
        display: inline-block;
        width: 91%;
        word-break: break-word;
        span {
          display: inline-block;
          @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
        }
      }
      div {
        border-bottom: size(1) solid var(--global--border--Color);
        padding: size(14) 0;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        & > span {
          display: inline-block;
          vertical-align: top;
          width: size(22);
          padding: size(4) 0;
          border-radius: 50%;
          text-align: center;
          background: var(--global--border--Color);
          @include font-styles($type: 'label', $size: 'sm', $weight: 'regular');
          margin-right: size(10);
        }
        svg {
          display: inline-block;
          vertical-align: middle;
          width: size(15);
          height: size(15);
          fill: transparent;
          stroke: #777;
          cursor: pointer;
          &.edit {
            vertical-align: top;
          }
        }
      }
    }
    .webhook-wrapper {
      margin-bottom: size(10);
      .inner-wrapper {
        background: #E91E63;
        h2 {
          color: var(--global--white--Color);
        }
        .btn-style-common {
          color: var(--global--white--Color);
          border: size(1) solid var(--global--white--Color);
        }
      }
    }
    .json-edit {
      position: absolute;
      right: size(10);
      top: 0;
    }
    .webhook-content, .firebase-content {
      background: var(--global--white--Color);
      box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
       0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
      border-radius: size(8);
      padding: size(20);
      &:after {
        content: "";
        clear: both;
        display: block;
      }
      h3 {
        @include font-styles($type: 'label', $size: 'md', $weight: 'semibold');
        margin-bottom: size(8);
      }
      .webhook__endpoint-wrapper, .firebase-content-wrapper {
        input {
          border: none;
          outline: none;
          width: 100%;
          padding: size(14);
          border: size(1) solid var(--global--gray--Color);
          border-radius: size(8);
          display: inline-block;
          background: var(--global--white--Color);
          text-align: left;
          margin-bottom: size(14);
          @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
          &::placeholder {
            color: #C4C4C4;
          }
        }
        textarea {
          height: size(130);
          padding: size(15);
          resize: none;
          width: 100%;
          border: none;
          outline: none;
          border: size(1) solid var(--global--gray--Color);
          border-radius: size(8);
          background: var(--global--white--Color);
          @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
          &::placeholder {
            color: #C4C4C4;
          }
        }
      }
      .alert-msg {
        float: right;
        @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
        padding-top: size(14);
        color: var(--global--gray--Color);
      }
      .add-endpoint {
        @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
        color: var(--global--common-link--Color);
        cursor: pointer;
      }
      .queries-wrapper {
        padding-top: size(14);
        text-align: right;
        div {
          display: inline-block;
        }
        button {
          margin-left: size(10);
          cursor: pointer;
        }
      }
      .action-btn {
        background: var(--global--common-link--Color);
        color: var(--global--white--Color);
        padding: size(8);
        cursor: pointer;
        outline: none;
        border: none;
        @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
        border-radius: size(5);
        float: right;
        &.confirm {
          margin-left: size(10);
        }
      }
    }
  }
  .webhook-wrapper {
    margin-bottom: size(10);
    .webhook__endpoint-wrapper {
      .input-box {
        input {
          &::placeholder {
            color: var(--global--supportive--Color);
          }
        }
      }
    }
  }
  .firebase-wrapper {
    .firebase-content {
      text-align: right;
      .action-btn {
        float: none;
        margin: size(20) 0  size(10) size(20);
      }
      h3 {
        text-align: left;
      }
      .firebase-content-wrapper {
        position: relative;
      }
      .generate-btn {
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        outline: none;
        color: var(--global--common-link--Color);
        cursor: pointer;
        @include font-styles($type: 'label', $size: 'sm', $weight: 'semibold');
      }
      .error-msg {
        @include font-styles($type: 'label', $size: 'sm', $weight: 'semibold');
        color: var( --global--error--BackgroundColor);
        text-align: left;
      }
    }
  }
}
</style>
